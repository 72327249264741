<template>
    <div class="page-wrapper">
        <section class="hero" v-if="!pageIsLoaded">
            <div class="data">
                <h1 class="title" data-inview="fadeInUp" data-delay="200">
                    Centres d’aide et de soutien <br />à la désintoxication
                </h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    Centre de désintoxication, d’aide et de soutien traitant la dépendance à
                    l’alcool, la drogue et aux médicaments depuis plus de 40 ans.
                </p>
                <h2 class="title small no-mb" data-inview="fadeInUp" data-delay="400">
                    Obtenez de l’aide !
                </h2>
                <div class="cta_wrap" data-inview="fadeInUp" data-delay="400">
                    <a href="/aide" class="cta regular-text">Écrivez nous</a>
                    <a href="tel:18667352366" class="cta regular-text">1-866 735-2366</a>
                </div>
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture lazyload" />
        </section>
        <section class="hero" v-else>
            <div class="data">
                <h1 class="title" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.hero.titre"></span>
                </h1>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.hero.texte"></span>
                </p>
                <h2 class="title small no-mb" data-inview="fadeInUp" data-delay="400">
                    Obtenez de l’aide !
                </h2>
                <div class="cta_wrap" data-inview="fadeInUp" data-delay="400">
                    <a :href="content.hero.boutonUrl[$route.meta.lang]" class="cta regular-text">{{ content.hero.bouton.customText }}</a>
                    <a :href="'tel:' + globals.header.titre2" class="cta regular-text">{{ globals.header.titre2 }}</a>
                </div>
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture lazyload" />
        </section>

        <section class="cta_wrap_mobile" v-if="pageIsLoaded">
            <h2 class="title small no-mb">Obtenez de l’aide !</h2>
            <a :href="content.hero.boutonUrl[$route.meta.lang]" class="cta regular-text">{{ content.hero.bouton.customText }}</a>
            <a :href="'tel:' + globals.header.titre2" class="cta regular-text">{{ globals.header.titre2 }}</a>
        </section>

        <section class="services_block" v-if="pageIsLoaded">
            <div class="left">
                <h2 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.blockInformations1.titre"></span>
                </h2>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.blockInformations1.texte"></span>
                </p>
                <a :href="content.blockInformations1.boutonUrl[$route.meta.lang]" class="cta regular-text" data-inview="fadeInUp" data-delay="400" >{{ content.blockInformations1.bouton.customText }}</a>
                <img src="@/assets/img/texture.svg" alt="texture" class="texture lazyload" />
            </div>
            <div class="right">
                <router-link class="service" :to="item.page.jsonUrl" v-for="(item, i) in servicesList" :key="i">
                    <h3 class="title small">{{ item.page.titre }}</h3>
                    <svg
                        fill="none"
                        class="plus"
                        viewBox="0 0 60 60"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="30" cy="30" r="30" />
                        <path
                            d="m30 20c-.7268 0-1.3158.589-1.3158 1.3158v7.3684h-7.3684c-.7268 0-1.3158.589-1.3158 1.3158s.589 1.3158 1.3158 1.3158h7.3684v7.3684c0 .7268.589 1.3158 1.3158 1.3158s1.3158-.589 1.3158-1.3158v-7.3684h7.3684c.7268 0 1.3158-.589 1.3158-1.3158s-.589-1.3158-1.3158-1.3158h-7.3684v-7.3684c0-.7268-.589-1.3158-1.3158-1.3158z"
                        />
                    </svg>
                    <img :data-src="item.page.heroSections.image.url" :alt="item.page.heroSections.image.titre" class="service_img lazyload" />
                </router-link>
            </div>
        </section>

        <section class="testimonials" data-inview="fadeInUp" data-delay="200" v-if="pageIsLoaded">
            <img src="@/assets/img/quote.svg" alt="quote" class="quote lazyload" data-inview="fadeInUp" data-delay="200" />
            <div class="carousel-wrap" data-inview="fadeInUp" data-delay="600">
                <img src="@/assets/img/texture-2.svg" alt="texture" class="texture lazyload" />
                <div class="main-carousel">
                    <div class="carousel-cell" v-for="(item, i) in content.temoignages" :key="i">
                        <div class="left">
                            <img
                                :src="item.image.url"
                                :alt="item.image.titre"
                            />
                        </div>
                        <div class="right">
                            <h4 class="title small">
                                <span v-html="item.titre"></span>
                            </h4>
                            <p class="regular-text" >
                                <span v-html="item.texte"></span>
                            </p>
                            <p class="title name">
                                <span v-html="item.sousTitre"></span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="houses_wrap" v-if="pageIsLoaded">
            <div class="title_wrap">
                <img
                    src="@/assets/img/icon-deux-maisons.svg"
                    alt="Icon maisons péladeau"
                    data-inview="fadeInUp"
                    data-delay="200"
                    class="lazyload"
                />
                <h3 class="title medium" data-inview="fadeInUp" data-delay="300">
                    Deux centres pour vous accueillir
                </h3>
            </div>
            <router-link
                :to="item.page.jsonUrl"
                class="house"
                data-inview="fadeInUp"
                data-delay="200"
                v-for="(item, i) in globals.centresEntry.data" :key="i"
            >
                <div class="img">
                    <img
                        :data-src="item.page.imageSimple.url"
                        :alt="item.page.imageSimple.titre"
                        class="lazyload"
                    />
                </div>
                <div class="data">
                    <p class="regular-text">{{ item.page.titre1 }}</p>
                    <h4 class="title small">{{ item.page.titre }}</h4>
                    <svg
                        fill="none"
                        class="plus"
                        viewBox="0 0 60 60"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <circle cx="30" cy="30" r="30" />
                        <path
                            d="m30 20c-.7268 0-1.3158.589-1.3158 1.3158v7.3684h-7.3684c-.7268 0-1.3158.589-1.3158 1.3158s.589 1.3158 1.3158 1.3158h7.3684v7.3684c0 .7268.589 1.3158 1.3158 1.3158s1.3158-.589 1.3158-1.3158v-7.3684h7.3684c.7268 0 1.3158-.589 1.3158-1.3158s-.589-1.3158-1.3158-1.3158h-7.3684v-7.3684c0-.7268-.589-1.3158-1.3158-1.3158z"
                        />
                    </svg>
                </div>
            </router-link>
        </section>

        <section class="text_img" v-if="pageIsLoaded">
            <div class="data">
                <h3 class="title medium" data-inview="fadeInUp" data-delay="200">
                    <span v-html="content.blockInformations2.titre"></span>
                </h3>
                <p class="regular-text" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.blockInformations2.texte"></span>
                </p>
                <a :href="content.blockInformations2.boutonUrl[$route.meta.lang]" class="cta regular-text" data-inview="fadeInUp" data-delay="400"
                    >{{ content.blockInformations2.bouton.customText }}</a
                >
            </div>
            <div class="img" data-inview="slideLeft" data-delay="200">
                <img :data-src="content.blockInformations2.image.url" :alt="content.blockInformations2.image.titre" class="lazyload" />
                <img src="@/assets/img/birds.svg" alt="birds" class="birds lazyload" />
            </div>
            <img src="@/assets/img/texture.svg" alt="texture" class="texture lazyload" />
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'
import Flickity from 'flickity'

export default {
    name: 'Home',

    data() {
        return {
            teamCarousel: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                            wrapAround: true,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })

                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })

                        setTimeout(() => {
                            this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        servicesList() {
            if (!this.globalsIsLoaded) {
                return []
            }

            return this.globals.servicesEntry.data.slice(0, 4)
        },
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero').classList.remove('remove_margin_top')
            }
        },
    },
}
</script>
